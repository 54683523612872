* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}
body{
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
 color: #626262;
 width: 100%;
height: 100%;
}
.color-primary{
  color: #0092a5;
  /* color: #00bfd8; */
}
.color-black{

	color: #121212 !important;
}
.text-large{
  font-size: 48px; 
}
.text-large-second{
	font-size: 18px; 
  }
.bg-w{
	background-color: #fff !important;
}
.icon-font-basic{
	font-size: 22px !important;
}
.fw600{
  font-weight: 700;
}
.text-medium{
  font-size: 38px;
}
.text-mid{
	font-size: 18px;
}
.h-250{
	height: 450px !important;
}
h1{
  color: #393939;
  font-weight: 700;
}

h2{
  color: #393939;
  font-size: 36px;
  font-weight: 700;
}

h4{
  color: #393939;
  font-size: 24px;
  font-weight: 600;
}
h5{
  color: #393939;
  font-size: 18px;
  font-weight: 600;
}
p{
  color: #626262;
  font-size: 16px;
}

.btn-primary{
	padding: 12px 28px;
	border-radius: 18px;
}
.btn-main{
	background-color: #fff;
	color: #0092a5;
	font-size: 18px;
	font-weight: 700;
}
.btn-second{
	background-color: #fff;
	color: #4c8890;
	border-color:#0092a5 ;
	font-size: 16px;
	font-weight: 600;
}
.btn-main-danger{
	background-color: #fff;
	color: #ca5139;
	font-size: 18px;
	font-weight: 700;
	border-color: #ca5139;
}
.btn-main:hover{
	background-color:#0092a5;
	color: #fff;
	transition: all ease-in 300ms;
}
.btn-second:hover{
	background-color:#0092a5;
	border-color:#0092a5 ;
	color: #fff;
	transition: all ease-in 300ms;
}

.second-container{
	padding-top: 8rem;
}

.contactCard .card-header h5{

padding-top: 5px;

}

.serviceCard{

	height: 360px;
}

.mainHead{
	line-height: 24px;
	letter-spacing: 1px;
}


/*Media Tags*/


/*****************************/
/*     Media Queries     */
/*****************************/	
/* Min-width width 768px */
@media (min-width: 768px) {
	
	/* General Styles */
	.p-heading {
		width: 85%;
		margin-right: auto;
		margin-left: auto;
	}
	/* end of general styles */


	/* Header */
	.header .header-content {
		padding-top: 10.5rem;
	}

	.header h1 {
		font-size: 3rem;
		line-height: 3.5rem;
	}
	/* end of header */
	

	
	/* end of extra pages */
}
/* end of min-width width 768px */


/* Min-width width 992px */
@media (min-width: 992px) {
	
	/* Navigation */
	.navbar-custom {
		padding: 2.125rem 1.5rem 2.125rem 2rem;
		box-shadow: none;
        background: transparent;
	}
	
	.navbar-custom .navbar-nav {
		margin-top: 0;
		margin-bottom: 0;
	}
	

	.navbar-custom .nav-item .nav-link {
		padding: 0.25rem 1rem 0.25rem 1rem;
		color: #fff;
		opacity: 0.8;
	}
	
	.navbar-custom .nav-item .nav-link:hover,
	.navbar-custom .nav-item .nav-link.active {
		color: #fff;
		opacity: 1;
	}

	.navbar-custom.top-nav-collapse {
        padding: 0.5rem 1.5rem 0.5rem 2rem;
		box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
		background-color: #fff;
	}

	.navbar-custom.top-nav-collapse .nav-item .nav-link {
		color: #393939;
		opacity: 1;
		font-size: 16px;
	}
	
	.navbar-custom.top-nav-collapse .nav-item .nav-link:hover,
	.navbar-custom.top-nav-collapse .nav-item .nav-link.active {
		color: #00bfd8;
	}

	.navbar-custom .dropdown-menu {
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-top: 0.75rem solid rgba(0, 0, 0, 0);
		border-radius: 0.25rem;
	}

	.navbar-custom.top-nav-collapse .dropdown-menu {
		border-top: 0.5rem solid rgba(0, 0, 0, 0);
		box-shadow: 0 0.375rem 0.375rem 0 rgba(0, 0, 0, 0.02);
	}

	.navbar-custom .dropdown-item {
		padding-top: 0.1rem;
		padding-bottom: 0.1rem;
	}

	.navbar-custom .dropdown-items-divide-hr {
		width: 84%;
	}

	.navbar-custom .social-icons {
		display: block;
		margin-left: 0.5rem;
	}

	.navbar-custom .fa-stack {
		margin-bottom: 0.1875rem;
		margin-left: 0.25rem;
		font-size: 0.75rem;
	}
	
	.navbar-custom .fa-stack-2x {
		color: #00bfd8;
		transition: all 0.2s ease;
	}
	
	.navbar-custom .fa-stack-1x {
		color: #fff;
		transition: all 0.2s ease;
	}

	.navbar-custom .fa-stack:hover .fa-stack-2x {
		color: #fff;
	}

	.navbar-custom .fa-stack:hover .fa-stack-1x {
		color: #00bfd8;
	}

	.navbar-custom.top-nav-collapse .fa-stack-2x {
		color: #00bfd8;
	}
	
	.navbar-custom.top-nav-collapse .fa-stack-1x {
		color: #fff;
	}

	.navbar-custom.top-nav-collapse .fa-stack:hover .fa-stack-2x {
		color: #00a7bd;
	}
	
	.navbar-custom.top-nav-collapse .fa-stack:hover .fa-stack-1x {
		color: #fff;
	}
	/* end of navigation */


	/* General Styles */
	.p-heading {
		width: 65%;
	}
	/* end of general styles */


	/* Header */
	.header {
		/* background: url('../images/header-background.jpg') center center no-repeat; */
		background-size: cover; 
	}

	.header .header-content {
		padding-top: 11.5rem;
		text-align: left;
	}

	.header .text-container {
		margin-top: 3rem;
		margin-bottom: 0;
	}
	/* end of header */


	
	/* end of extra pages */
}
/* end of min-width width 992px */


/* Min-width width 1200px */
@media (min-width: 1200px) {
	
	/* Navigation */
	.navbar-custom {
		padding: 2.125rem 5rem 2.125rem 5rem;
	}

	.navbar-custom.top-nav-collapse {
        padding: 0.5rem 5rem 0.5rem 5rem;
	}
	/* end of navigation */

	
	/* General Styles */
	.p-heading {
		width: 55%;
	}
	/* end of general styles */


	/* Header */
	.header .header-content {
		padding-top: 12.5rem;
	}

	.header .text-container {
		margin-top: 5.375rem;
		margin-left: 1rem;
		margin-right: 2rem;
	}

	.header .image-container {
		margin-left: 2rem;
		margin-right: 1rem;
	}
	/* end of header */
	

}
/* end of min-width width 1200px */