.custom-caro{
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}
.caro-card{
    margin: 5px 8px;
    height: 300px;
}
.slick-dots li button:before {
font-size: 8px;
}