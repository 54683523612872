.header .header-content {
    padding-top: 8rem;
    padding-bottom: 2rem;
    text-align: center;
}
.header .header-content{
    text-align: left;
}
.header h1{  
    line-height: 56px;
    
}
.main-large{
    font-weight: 700;
}