

.small-cont p{
    
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}
.rigthArrow{
    font-size: 24px;
}